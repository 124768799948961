const slugify = (str = '') => {
	return str
		.toLowerCase()
		.replace(/\s\s\s\s/g, ' ')
		.replace(/\s\s\s/g, ' ')
		.replace(/\s\s/g, ' ')
		.trim()
		.replace(/ /g, '-')
		.replace(/,/g, '-')
		.replace(/--/g, '-')
		.replace(/---/g, '-')
		.replace(/----/g, '-')
		.trim();
};
export default slugify;
