/**
 * Replaces the window.console to only output tu the console
 * on dev mode or when a tomer=1 exists on the url
 * @type {Console}
 */
import { track } from './tracking';
import { EVENTS } from '../consts/events';
import { IS_ALLOW_CONSOLE_LOG, IS_CLIENT, IS_DEBUG, IS_DEV, IS_LOCAL, IS_TRACK_ERRORS } from '../consts/general';
import { USE_SENTRY } from '../consts/env';

const verbose = IS_ALLOW_CONSOLE_LOG || IS_DEBUG;
let initialValue = {};
if (IS_CLIENT) {
	window.__$inform = window.__$inform || [];
}

initialValue = {
	info: console.info.bind(console),
	inform: (event, data = {}) => {
		const screen = {};
		if (IS_CLIENT) {
			screen.ww = window.innerWidth;
			screen.wh = window.innerHeight;
		}
		data = {
			...data,
			...screen,
		};
		if (verbose) {
			console.debug(`INFORM : ${event}`, JSON.stringify(data));
		}
		if (IS_CLIENT) {
			window.__$inform.push({
				event,
				data,
			});
		}
	},
	error: function (...args) {
		if (verbose) {
			console.error(...args);
		}
		if (IS_TRACK_ERRORS) {
			const [action, name, stack] = (() => {
				const isCaught = args[0] !== EVENTS.ERROR.UNCAUGHT;
				const errorObject = args.find(arg => arg instanceof Error) || {
					message: args[0],
					stack: args.slice(1).join('\n'),
				};

				if (USE_SENTRY) {
					// const Sentry = require('@sentry/browser');
					// let errorObjectSentry = Object.assign(errorObject);
					// errorObjectSentry.message = `${args[0]}, ${JSON.stringify(args[1])}, ${JSON.stringify(args[2])}`;
					// Sentry.captureException(errorObjectSentry);
				}

				return [isCaught ? EVENTS.ERROR.CAUGHT : EVENTS.ERROR.UNCAUGHT, errorObject.message, errorObject.stack];
			})();
			//
			track(action, {
				name,
				eventValueString15: stack,
			});
		}
	},
	display: msg => {
		if (verbose) {
			this.inform(msg);
		}
	},
};

export const prompt = ['debug', 'log', 'warn', 'dir', 'error'].reduce(
	(acc, func) => ({
		...acc,
		// [func]: () => null,
		[func]: verbose ? (...args) => console[func](`BRZ`, IS_DEV || IS_LOCAL ? args : JSON.stringify(args)) : new Function(),
	}),
	initialValue
);
