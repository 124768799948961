import { default as NextHead } from 'next/head';
import config from 'src/config';
import { primaryColor } from 'src/lib/consts/styles';
import { useRouter } from 'next/router';
import { getEntry } from 'src/lib/tools/store/store';
import { useQ } from 'src/modules/hooks/useQ';
import { properCase } from 'src/lib/utils/proper-case';
import { COOKIE_BOT_NON_US, COOKIE_BOT_US, IS_CLIENT } from 'src/lib/consts/general';
import { routes } from 'src/lib/consts/routes';
import unslugify from 'src/lib/utils/unslugify';

export default function Head() {
	const { asPath, route, query } = useRouter();
	const top10Lists = getEntry('top10Lists');

	let q = useQ() || '';
	const canonicalTitle = query.best ? properCase(unslugify(query.best[0].replace('best-', ''))) : q;

	const canonicalURL = `https://${config.theme}${asPath.slice(0, asPath.indexOf('?'))}`;

	const title = config.head.titles[route](canonicalTitle, top10Lists?.[0]?.offers.length);
	const description = config.head.descriptions[route](canonicalTitle);
	const keywords = canonicalTitle ? canonicalTitle.split(' ').join(', ') : '';

	const additionalAttrs = {};
	if (route === routes.DNSMPI) {
		additionalAttrs['data-georegions'] = `{'region':'US','cbid':'${COOKIE_BOT_US}'}`;
	}

	return (
		<NextHead>
			<meta name="viewport" content={'width=device-width,initial-scale=1,maximum-scale=1'} />
			<meta name="robots" content="noindex" />
			<link rel="manifest" href="/manifest.json"></link>
			<meta name="theme-color" content={primaryColor} />
			{/* <link rel="apple-touch-icon" sizes="57x57" href="/favicon_57x57.png" />
			<link rel="apple-touch-icon" sizes="114x114" href="/favicon_114x114.png" />
			<link rel="apple-touch-icon" sizes="72x72" href="/favicon_72x72.png" />
			<link rel="apple-touch-icon" sizes="144x144" href="/favicon_144x144.png" />
			<link rel="apple-touch-icon" sizes="60x60" href="/favicon_60x60.png" />
			<link rel="apple-touch-icon" sizes="120x120" href="/favicon_120x120.png" />
			<link rel="apple-touch-icon" sizes="76x76" href="/favicon_76x76.png" />
			<link rel="apple-touch-icon" sizes="152x152" href="/favicon_152x152.png" />
			<link rel="icon" type="image/png" href="/favicon_196x196.png" sizes="196x196" />
			<link rel="icon" type="image/png" href="/favicon_96x96.png" sizes="96x96" />
			<link rel="icon" type="image/png" href="/favicon_32x32.png" sizes="32x32" />
			<link rel="icon" type="image/png" href="/favicon_16x16.png" sizes="16x16" />
			<link rel="icon" type="image/png" href="/favicon_128.png" sizes="128x128" /> */}
			{/* <meta name="msapplication-TileColor" content="#FFFFFF" />
			<meta name="msapplication-TileImage" content="/favicon_144x144.png" />
			<meta name="msapplication-square70x70logo" content="/favicon_70x70.png" />
			<meta name="msapplication-square150x150logo" content="/favicon_150x150.png" />
			<meta name="msapplication-wide310x150logo" content="/favicon_310x150.png" />
			<meta name="msapplication-square310x310logo" content="/favicon_310x310.png" /> */}
			<title>{title || 'BestReviews.zone'}</title>
			<meta name="description" content={description} />
			<meta name="keywords" content={keywords} />
			<link rel="canonical" href={canonicalURL} />
			<meta property="og:title" content={title} />
			<meta property="og:type" content={'website'} />
			<meta property="og:url" content={canonicalURL} />
			<meta property="og:image" content={'/bestreviews.zone/logo.svg'} />
			<meta name="og:description" content={description} />
			<meta name="application-name" content={config.theme} />
			<script
				type="text/javascript"
				dangerouslySetInnerHTML={{
					__html: ` (function(h,o,t,j,a,r){
						h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
						h._hjSettings={hjid:2571109,hjsv:6};
						a=o.getElementsByTagName('head')[0];
						r=o.createElement('script');r.async=1;
						r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
						a.appendChild(r);
					})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`,
				}}
			/>
			{IS_CLIENT ? (
				<script
					async
					id={'Cookiebot'}
					key={'Cookiebot'}
					src={'https://consent.cookiebot.com/uc.js'}
					data-cbid={COOKIE_BOT_NON_US}
					data-blockingmode={'auto'}
					type={'text/javascript'}
					{...additionalAttrs}></script>
			) : null}
			{IS_CLIENT ? (
				<script
					async
					key={'adsbygoogle'}
					src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5520814690854067"
					crossOrigin="anonymous"></script>
			) : null}
		</NextHead>
	);
}
