// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { APP_VERSION, NEXT_PUBLIC_SENTRY_DSN, SENTRY_DSN } from 'src/lib/consts/env';
import { IS_PROD } from 'src/lib/consts/general';

const sentryDSN = SENTRY_DSN || NEXT_PUBLIC_SENTRY_DSN;

if (IS_PROD) {
	process.env.SENTRY_RELEASE = APP_VERSION;
	Sentry.init({
		dsn: sentryDSN || 'https://df3da804da804ee7ac1ab74ddd4fa259@o206854.ingest.sentry.io/5872074',
		// Adjust this value in production, or use tracesSampler for greater control
		tracesSampleRate: 0.1,
		release: APP_VERSION,
		// ...
		// Note: if you want to override the automatic release value, do not set a
		// `release` value here - use the environment variable `SENTRY_RELEASE`, so
		// that it will also get attached to your source maps
	});
}
