import '@/styles/global.scss';
import { Fragment } from 'react';
import { pageview } from '@/tools/tracking';
import { useEffect } from 'react';
import Head from '@/components/head/head';
import { prompt } from '@/tools/prompt';
import { initializeStore, resetClientState } from 'src/lib/tools/store/store';
import { sendNavEvents, uniqueTrack } from 'src/lib/tools/tracking';
import { useQ } from 'src/modules/hooks/useQ';
import { FRM_SESSION_ID, PERSIST_SESSION_DATA } from 'src/lib/consts/general';
import { getCookie, setCookie } from 'src/lib/utils/session';
import uuid from 'src/lib/utils/uuid';
import { APP_VERSION } from 'src/lib/consts/env';
import { EVENTS } from 'src/lib/consts/events';

// if (IS_CLIENT) {
// 	resetClientState(INITIAL_STATE, '');
// }

export default function BestReviewsApp(props) {
	const { Component, pageProps, router } = props;
	const { asPath } = router;

	const zState = initializeStore(pageProps.zStore).getState();

	const q = useQ();

	useEffect(() => {
		const { TestsManager } = require('src/lib/tools/testing');

		prompt.debug(`v${APP_VERSION}`);
		prompt.debug(`TCL ~ zStore`, zState.test);

		resetClientState(zState, asPath, q);
		sendNavEvents();
		pageview(asPath);
		setCookie(PERSIST_SESSION_DATA, { test: TestsManager.getCurrentTest() }, null);

		const uniqueSessionId = zState.sessionId || getCookie(FRM_SESSION_ID) || uuid(); // If session id generated from server take it, otherwise generate new one and store it
		setCookie(FRM_SESSION_ID, uniqueSessionId, null);
		prompt.debug(`TCL ~ sessionId`, uniqueSessionId);
		prompt.debug(`TCL ~ current test`, TestsManager.getCurrentTest());

		uniqueTrack(EVENTS.GENERAL.ENTER);
	}, []);

	return (
		<Fragment>
			<Head />
			<Component {...pageProps} />
		</Fragment>
	);
}
