import { getEntry, updateM } from './store/store';
import config from './../../config';

/**
 * Test props example:
 * { testId: 107, variants: ['n', '1'], theme: 'showroom' }
 *
 * Client Test props example:
 * { testId: 73, variants: ['n', '1', '2', '3', '4'], testBlock: 2, client: true, testWeight: 10, variantWeights: [50, 0, 50, 0, 0] }
 */

// Add here active test objects
const activeTests = [];

// in case of ftid and ftg in query params the forcedTest will be populated with the forced test from the feed
let forcedTest = {};

// ftid and ftg are query params described the test id and test group the user need to be forced to
const getForcedTestParams = () => {
	let { ftid, ftg } = getEntry('urlParams');
	return { ftid, ftg };
};

const shouldForceTest = () => {
	let { ftid, ftg } = getEntry('urlParams');
	if (ftid && ftg) return true;
};

// Set user in current _test
const setCurrentTest = (_test = {}) => {
	updateM('Update Test', { test: _test });
};

// Is test is in active tests
const isActiveTest = testId => Boolean(activeTests.filter(t => t.theme === config.theme).find(_test => `${_test.testId}` === `${testId}`));

// Is test is in active client tests - active client tests are tests that initalize and distributed in client and not from the feed
const isActiveClientTest = testId => Boolean(activeTests.filter(t => t.client).find(_test => `${_test.testId}` === `${testId}`));

export const TestsManager = {
	// Remove user from current _test
	deleteCurrentTest() {
		updateM('Remove Test', { test: {} });
	},
	getCurrentTest() {
		return getEntry('test');
	},
	updateTest(testData) {
		if (!testData) {
			return null;
		}

		const isForcedTest = Boolean(forcedTest && forcedTest.ftid);

		// if we are not in compliant mode and (no current test or forced test) update current test
		// !getEntry('isCompliant') && 
		if ((!TestsManager.isInTest(TestsManager.getCurrentTest()) && testData.testId) || (isForcedTest && testData.testId)) {
			setCurrentTest(testData);
		}
		return getEntry('test');
	},
	isInTest(testConst = null) {
		return testConst && isActiveTest(testConst.testId) && `${TestsManager.getCurrentTest().testId}` === `${testConst.testId}`;
	},
	isInTestGroup(testConst = {}, group) {
		const test = TestsManager.getCurrentTest();
		let is = false;

		if (!isActiveTest(testConst.testId)) {
			// Not active test, return false
			return is;
		}
		if (!testConst || (!group && group !== 0)) {
			// No testConst sent to function or no group return false
			return is;
		}
		if (+test.testId !== +testConst.testId) {
			// If current test id not equal to testConst id return false
			return is;
		}
		if (Array.isArray(group)) {
			// In case group is Array check is current test group is one of the groups in the array
			group.map(item => {
				if ((item === 'n' && test.testGroup === 'n') || testConst.variants[item] === test.testGroup) is = true;
			});
			return is;
		}

		// In case group is not array just check the group
		return testConst.variants[group] === test.testGroup || (group === 'n' && test.testGroup === 'n');
	},
	experiment(testConst) {
		if (forcedTest && forcedTest.ftid && isActiveClientTest(forcedTest.ftid)) {
			// force client to test
			setCurrentTest({
				testId: forcedTest.ftid,
				testBlock: testConst.testBlock,
				testGroup: forcedTest.ftg,
			});
			return;
		}

		const currentTest = TestsManager.getCurrentTest();
		if (currentTest && currentTest.testId) return; // already in test

		if (!isActiveClientTest(testConst.testId) || !testConst.client || !testConst.testWeight || !testConst.testId) return; // check validation of testConst

		const isEnterTest = Math.random() * 100 <= testConst.testWeight; // enter user to test or not?
		if (!isEnterTest) return;

		const totalWeight = testConst.variantWeights.reduce((a, b) => a + b, 0); // sum all variant weights and check if is 100
		if (totalWeight != 100) return;

		const groupWeights = [];
		let currentGroup = 0;
		while (currentGroup < testConst.variants.length) {
			// build all groups array
			for (let i = 0; i < testConst.variantWeights[currentGroup]; i++) {
				groupWeights[groupWeights.length] = testConst.variants[currentGroup];
			}
			currentGroup++;
		}

		const groupWeight = Math.floor(Math.random() * totalWeight); // random user to which group to enter

		if (!groupWeights[groupWeight]) return; // just to make sure

		setCurrentTest({
			testId: testConst.testId,
			testBlock: testConst.testBlock,
			testGroup: groupWeights[groupWeight],
		});
	},
};

(
	// initalize current test and forced test
	() => {
		if (typeof window === 'undefined') {
			return;
		}

		const testInStore = TestsManager.getCurrentTest();
		if (testInStore && testInStore.testId) {
			TestsManager.updateTest(testInStore);
		}

		if (shouldForceTest()) {
			forcedTest = getForcedTestParams();
		}
	}
)();