import { updateM } from './store/store';
const MobileDetect = require('mobile-detect');

/**
 * Calculates the current device based on user agent
 * @return {{}}
 */
export const updateDevices = (ua, silent = false) => {
	const md = new MobileDetect(ua);

	const isMobile = Boolean(md.mobile());
	const isTablet = Boolean(md.tablet());

	const devices = {
		isMobileDevice: isMobile,
		isTabletDevice: isTablet,
		isComputerDevice: !isMobile && !isTablet,
	};
	if (!silent) {
		updateM('Reload devices', { devices });
	}
	return devices;
};
