import { getEntry, updateM } from './store/store';
import { prompt } from './prompt';
import { buildUrl } from '../utils/build-url';
import { debounce } from '../utils/debounce';
import { IS_CLIENT, IS_DEBUG, IS_PROD, NAV_EVENTS_TTL, STORE_SESSION_KEY } from '../consts/general';
import { cleanObject } from '../utils/clean-object';
import { properCase } from '../utils/proper-case';
import { sessionGetWithoutDefault, sessionSet } from '../utils/session';
import { EVENTS } from '../consts/events';
import { dqs } from './dom';
import { getPage } from '../utils/whoami';
import config from '../../config';
import { APP_VERSION, PROJECT, SILENCE_CHERAMI } from '../consts/env';

/**
 * Base request for any event submission
 * @return {{}}
 */
const baseRequest = () => {
	const params = getEntry('urlParams');
	const test = getEntry('test');
	const requestId = getEntry('requestId');
	
	return {
		appVersion: APP_VERSION,
		appName: getEntry('theme'),
		project: PROJECT,
		keyword: params.q || getEntry('categoryName') || getEntry('keywordBestPage'),
		cid: getEntry('cid'),
		// page: getEntry('userPage'),
		resolution: IS_CLIENT ? `${window.innerWidth}x${window.innerHeight}` : '',
		eventValueInteger3: IS_CLIENT ? window.scrollY : '',
		sessionId: getEntry('sessionId'),
		trafficSourceId:
			params.trafficSourceId ||
			params.gclid ||
			params.ob_click_id ||
			params.zm_click_id ||
			params.msclkid ||
			params.tb_click_id ||
			params.gbraid ||
			params.wbraid ||
			params.clickid ||
			params.click_id ||
			getEntry('sessionId'),
		utmCampaign: params.utm_campaign,
		utmContent: params.utm_content,
		utmMedium: params.utm_medium,
		utmSource: params.utm_source,
		utmTerm: params.utm_term,
		trafficSourceAdGroupId: params.adgroup_id,
		trafficSourceCampaignId: params.campaignid,
		trafficSourceDevice: params.device,
		trafficSourceKeywordId: params.keyword,
		trafficSourceNetwork: params.network,
		trafficSourceMatchType: params.matchtype,
		trafficSourceAdExtensionId: params.feeditemid,
		traficSourceAdId: params.adid,
		eventValueString9: getEntry('currentPage'),
		eventValueInteger6: Date.now(),
		requestId,
		...test,
	};
};

/**
 * Holds all events as they are none-blocking
 * @type {*[]}
 */
let PENDING_TRACKING = [];

const trackAPI = payload => {
	fetch(`https://${buildUrl(config.cheramiUrl, !IS_PROD || IS_DEBUG ? payload.map(event => event.action) : {})}`, {
		method: 'POST',
		mode: 'cors',
		body: JSON.stringify(payload),
		headers: {
			'Content-Type': 'application/json',
		},
	}).catch(err => {
		prompt.error('Error sending report to CherAmi ', err);
	});
};

const trackingDebounced = debounce(() => {
	if (SILENCE_CHERAMI) {
		return;
	}
	trackAPI(PENDING_TRACKING);
	PENDING_TRACKING.forEach(event => {
		if (IS_PROD && IS_CLIENT && window.gtag) {
			window.gtag('event', event.action, { event_category: event.cid, event_label: event.eventValueString7, value: event.keyword });
		}
		prompt.debug(`TRACKING -> ${event.action}`, IS_PROD ? JSON.stringify(event) : event);
	});
	PENDING_TRACKING = [];
});

/**
 * Send an event to cherami. All it does actually is push an event object to PENDING_TRACKING
 * @param {string} action
 * @param {{}} data - will override/add to the default event data
 */
export const track = (action, data = {}) => {
	data = { ...baseRequest(), ...data };
	PENDING_TRACKING.push({ ...data, action });
	trackingDebounced();
};

export const uniqueTrack = (action, data = {}) => {
	const key = STORE_SESSION_KEY + 'unique';
	const isInSession = sessionGetWithoutDefault(key) === 'true';
	if (!isInSession) {
		track(action, data);
		sessionSet(key, 'true');
	}
};

export const pixel = productData => {
	console.log("TCL ~ ~ productData", productData);
	const urlParams = getEntry('urlParams');
	let { id, vendorId, ecpc } = productData;
	ecpc = ecpc || null;
	const pixelData = cleanObject({
		...baseRequest(),
		ob_click_id: urlParams.ob_click_id,
		k_user_id: urlParams.k_user_id,
		zm_click_id: urlParams.zm_click_id,
		category: urlParams.category,
		geo: urlParams.geo,
		amz: urlParams.amz,
		sbclkid: urlParams.sbclkid,
		rtbclickid: urlParams.rtbclickid,
		vpricing: urlParams.vpricing,
		clickId: id,
		vendorId,
		ecpc,
	});
	prompt.debug(`pixeling`, pixelData);
	const src = buildUrl(config.pixelsUrl, pixelData);
	const markup = `<iframe src="${src}" style="width: 1px;height: 1px;border: none;opacity: 0"></iframe>`;
	dqs('footer').insertAdjacentHTML('beforeend', markup);
};

/**
 * Send an event to cherami on ui actions ending in site navigation.
 * The event will be kept in the session storage and sent post navigation with a ttl of 1 sec
 * @param {string} action
 * @param {{}} data - will override/add to the default event data
 */
export const trackOnNav = (action, data = {}) => {
	updateM('TrackOnNav', {
		navEvents: [
			...getEntry('navEvents'),
			{
				action,
				data,
				t: Date.now(),
			},
		],
	});
};

export const pageview = path => {
	track('pageview', {
		name: properCase(EVENTS.IMPS.PAGE_VIEW.replace('%PAGE%', path || getPage())),
		eventValueInteger1: Date.now() - getEntry('startTime'),
	});

	const urlParams = getEntry('urlParams');
	if (!urlParams.sbclkid) {
		return;
	}

	// cto bidder pixel
	fetch(buildUrl(config.ctoUrl, { type: 'l1', sbclkid: urlParams.sbclkid }), {
		method: 'GET',
		mode: 'cors',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
		},
	})
		.then(() => {})
		.catch(err => console.error('Error sending report to cto ', err));
};

export const runNavEvents = () => {
	const navEvents = getEntry('navEvents') || [];
	while (navEvents.length) {
		const event = navEvents.shift();
		if (event.t >= NAV_EVENTS_TTL) {
			track(event.action, event.data);
		} else {
			prompt.warn('Expired Event', event.action, event.data);
		}
	}
	return navEvents;
};

// send navigation events
export const sendNavEvents = () => {
	const navEvents = runNavEvents();
	updateM('Send nav events', { navEvents: [...navEvents] }, true);
};
