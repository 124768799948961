/**
 * Returns an object without undefined
 * @param {{}} source
 * @return {{}}
 */
export const cleanObject = source =>
	Object.entries(source)
		.filter(entry => typeof entry[1] !== 'undefined' && entry[1] !== null)
		.reduce((accumulator, current) => ({
			...accumulator,
			[current[0]]: current[1]
		}), {});