import config from "src/config";
import { IS_CLIENT } from "../consts/general";

// SESSION STORAGE
export const sessionGet = (key, defaultValue) => {
	return (
		sessionStorage.getItem(key) ||
		(() => {
			if (defaultValue) {
				sessionStorage.setItem(key, defaultValue);
			}
			return sessionStorage.getItem(key);
		})()
	);
};
export const sessionSet = (key, value) => {
	sessionStorage.setItem(key, value);
};
export const sessionGetWithoutDefault = key => {
	return sessionStorage.getItem(key);
};

// COOKIES
export const setCookie = (cname, cvalue, exdays, domain = '') => {
	if (!IS_CLIENT) {
		return null;
	}
	if (typeof cvalue !== 'string') {
		cvalue = JSON.stringify(cvalue);
	}
	let expires = '';
	if (exdays) {
		var d = new Date();
		d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
		expires = 'expires=' + d.toUTCString() + ';';
	}
	document.cookie = `${cname}=${cvalue};${expires}path=/;domain=.${domain || config.theme};SameSite=Lax;`;
};
export const getCookie = (cname, cookies = '') => {
	const cookiesToSearchFrom  = IS_CLIENT ? document.cookie : cookies;

	if (!cookiesToSearchFrom) {
		return null;
	}
	
	var name = `${cname}=`;
	var ca = cookiesToSearchFrom.split(';');
	for (var i = 0; i < ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) == ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) == 0) {
			return c.substring(name.length, c.length);
		}
	}
	return '';
};