import { DEFAULT_CID } from "src/lib/consts/general";
import { DEFAULT_FILTERS } from "../search-feed/filters";

export const INITIAL_STATE = {
	urlParams: {},
	cid: DEFAULT_CID,
	searchOpen: false,
	categoryName: '',
	navEvents: [],
	top10s: [],
	top10Lists: [],
	isHeaderLoader: false,
	isSearchForm: false,

	// SEARCH FEED
	filters: DEFAULT_FILTERS,
	isCompliant: true,
	infScroll: true,
	pageMode: false,
	pages: [],
	textAds: [],
	productAds: [],
	extraProducts: [],
	userPage: 1,
	feedPage: 0,
	wentForProducts: false,
	vendorProps: {},
	usedFilters: {},
	test: {},
	maxProductPerPage: 20,
	yProducts: [],
	noMoreProducts: false,
	lastTextAdsIndex: 2
	//
};
