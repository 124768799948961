/**
 * Small utility for debouncing functions
 * @param callback
 * @param ttl
 * @returns {function(*=): void}
 */
export const debounce = (callback, ttl = 0) => {
	let timeoutId = null;
	return (...args) => {
		clearTimeout(timeoutId);
		timeoutId = setTimeout(() => {
			return callback(...args);
		}, ttl);
	};
};