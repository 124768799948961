/**
 * Get all query params from a given URL
 * @param url
 * @return {Object.<string,*>}
 */

import { properCase } from './proper-case';

export function paramsFromUrl(url) {
	return Array.from(new URL(url).searchParams.entries()).reduce((a, b) => ({ ...a, [b[0]]: decodeURIComponent(b[1]) }), {});
}

export function categoryNameFromUrl(url) {
	if (!url) {
		return '';
	}
	return properCase(decodeURIComponent(url.split('/')[2].substring('best-'.length) || '').replace(/-/g, ' '));
}
