// FILTERS
export const FILTERS = [
	'minPrice',
	'maxPrice',
	'isFreeShipping',
	'brands',
	'minDiscount',
	'sortBy',
	'sortValue',
	'color',
	'size',
	'condition',
	'fcategoryId',
];
export const DEFAULT_FILTERS = {
	productAds: {
		brandName: {
			values: [],
		},
		currentPrice: {
			values: {
				min: 0,
				max: 1000,
			},
		},
		color: {
			values: [],
		},
		size: {
			values: [],
		},
		condition: {
			values: [],
		},
	},
};
