const unslugify = (string = '') => {
	return decodeURIComponent(
		string
			.replace(/-/g, ' ')
			.replace(/(%\D)|(%$)/g, '%25')
			.replace(/[a-z][a-z]*-?/g, ([f, ...rest]) => f + rest.join('').replace('-', ' '))
	);
};

export default unslugify;
