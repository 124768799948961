// SENTRY
const USE_SENTRY = process.env.USE_SENTRY;
const SENTRY_DSN = process.env.SENTRY_DSN;
const NEXT_PUBLIC_SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;
const SENTRY_RELEASE = process.env.SENTRY_RELEASE;
//

// APP
const APP_VERSION = process.env.APP_VERSION;
const PROJECT_ENV = process.env.PROJECT_ENV;
const ENV_PATH = process.env.ENV_PATH;
const PROJECT = process.env.PROJECT;
//

// EVENTS
const ALLOW_CONSOLE_LOG = process.env.ALLOW_CONSOLE_LOG;
const TRACK_ERRORS = process.env.TRACK_ERRORS;
const SILENCE_CHERAMI = process.env.SILENCE_CHERAMI;
//

module.exports = {
	USE_SENTRY,
	SENTRY_DSN,
	NEXT_PUBLIC_SENTRY_DSN,
	SENTRY_RELEASE,
	APP_VERSION,
	PROJECT_ENV,
	ENV_PATH,
	PROJECT,
	ALLOW_CONSOLE_LOG,
	TRACK_ERRORS,
	SILENCE_CHERAMI,
};
