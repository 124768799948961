import { routes } from '../consts/routes';
import { getEntry } from '../tools/store/store';
import slugify from './slugify';

/**
 *  Generate an encoded url
 * @param {string} url - domain with protocol/port and path
 * @param {Object.<string,*>} params - url params to encode
 * @return {*}
 */
export function buildUrl(url = '', params = {}) {
	const keys = Object.keys(params);
	if (url.endsWith('/')) url = url.substr(0, url.length - 1);
	return !keys.length ? url : `${url}?${keys.map(key => `${key}=${encodeURIComponent(params[key])}`).join('&')}`;
}

/**
 * Generates a url for the search page.
 * @param urlParams
 * @return {*}
 */
export const searchUrl = (urlParams, filterOut = []) => specialPageUrl(urlParams, filterOut, routes.SEARCH);

/**
 * Generates a url for the top10 best page.
 * @param urlParams
 * @return {*}
 */
export const top10UrlBest = (itemName, params) => top10PageUrlBest(itemName, params, routes.TOP10_BEST);
const top10PageUrlBest = (itemName, params = {}, prefixRoute) => {
	const urlParams = getEntry('urlParams');
	const noQ = { ...urlParams, ...params };
	delete noQ.q;
	if (typeof window !== 'undefined') {
		return buildClientUrl(`${prefixRoute}/${encodeURIComponent(slugify(itemName))}`, noQ, [
			'vendorId',
			'productId',
			'pid',
		]);
	}
	return buildUrl(`${prefixRoute}/${encodeURIComponent(slugify(itemName))}`, noQ);
};

/**
 * Generates a url for the search page.
 * @param urlParams
 * @return {*}
 */
export const specialPageUrl = (urlParams, filterOut = [], prefixRoute, suffixRoute = '') => {
	const noQ = { ...urlParams };
	delete noQ.q;
	if (typeof window !== 'undefined')
		return buildClientUrl(`${prefixRoute}/${encodeURIComponent(slugify(urlParams.q))}${suffixRoute ? '/' + suffixRoute : ''}`, noQ, [
			'vendorId',
			'productId',
			'pid',
			...filterOut,
		]);
	return buildUrl(`${prefixRoute}/${encodeURIComponent(slugify(urlParams.q))}${suffixRoute ? '/' + suffixRoute : ''}`, noQ);
};

/**
 *  Generate an encoded client url with all the required query params
 * @param {string} url - domain with protocol/port and path
 * @param {Object.<string,*>} params - url params to encode
 * @param {Array.<string>} [filterOut] optional filtering for persisted params
 * @return {*}
 */
export function buildClientUrl(url = '', params = {}, filterOut = [], isQ = false) {
	const urlParams = getEntry('urlParams');
	const _urlParams = { ...urlParams };

	filterOut.forEach(k => delete _urlParams[k]);
	delete _urlParams.page;
	if (!isQ) {
		delete _urlParams.q;
	}

	return buildUrl(url, { ..._urlParams, ...params });
}

/**
 * Wrapper to build href for nextjs Link component
 * @param {*} route
 * @returns
 */
export function buildClientUrlObj(route, params = {}, filterOut = [], isQ = false) {
	const urlParams = getEntry('urlParams');
	const _urlParams = { ...urlParams };

	filterOut.forEach(k => delete _urlParams[k]);
	delete _urlParams.page;
	if (!isQ) {
		delete _urlParams.q;
	}

	return { pathname: route, query: { ..._urlParams, ...params } };
}

// Add / Update a key-value pair in the URL query parameters
export function updateUrlParameter(url, key, value) {
	// remove the hash part before operating on the url
	const i = url.indexOf('#');
	const hash = i === -1 ? '' : url.substr(i);
	url = i === -1 ? url : url.substr(0, i);

	const re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i');
	const separator = url.indexOf('?') !== -1 ? '&' : '?';
	if (url.match(re)) {
		url = url.replace(re, '$1' + key + '=' + value + '$2');
	} else {
		url = url + separator + key + '=' + value;
	}
	return url + hash;
}
