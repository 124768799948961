import { IS_CLIENT } from '../consts/general';

export const getPage = () => {
	return location.pathname.substr(1).split('/')[0].split('.')[0] || 'home';
};

export const isTop10Page = (url = '') => {
	if (IS_CLIENT) {
		url = location.pathname;
	}
	const uriParts = url.split('/');
	const secondUriPart = uriParts[2] || '';
	return secondUriPart.indexOf('best-') === 0;
};
