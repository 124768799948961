import { ALLOW_CONSOLE_LOG, PROJECT_ENV, TRACK_ERRORS } from "./env";

export const NAV_EVENTS_TTL = Date.now() - 5000; // TODO
export const FRM_SESSION_ID = 'brz-ses';
export const STORE_SESSION_KEY = `store-br`;
export const PERSIST_SESSION_DATA = 'brz-per';
export const DEFAULT_CID = 9291;
export const WEBOX_CID = 9473;
export const COMPLAINT_CIDS = [DEFAULT_CID, WEBOX_CID];
export const IS_DEBUG = (() => {
	if (typeof location !== 'undefined') {
		return location.href.indexOf('tomer=1') > -1;
	}
	return false;
})();
export const REQUEST_ID_PREFIX = 'BRZ-';
export const IS_CLIENT = (() => typeof window !== 'undefined')();
export const MONTHS_NAMES = {
	0: 'January',
	1: 'February',
	2: 'March',
	3: 'April',
	4: 'May',
	5: 'June',
	6: 'July',
	7: 'August',
	8: 'September',
	9: 'October',
	10: 'November',
	11: 'December',
};
export const GA_TRACKING_ID = 'G-FE9MBWYFV8';
export const YAHOO = 'y';
export const YAHOO_PRODUCT = 'yp';
export const AMAZON = '3';
export const EBAY = 'eb';
export const COOKIE_BOT_NON_US = '0977d5ea-9e5f-4668-a944-9d469515f526';
export const COOKIE_BOT_US = '85be3047-1eee-469f-89e8-7f7ace8cc474';

// ENV
export const IS_ALLOW_CONSOLE_LOG = ALLOW_CONSOLE_LOG === 'true';
export const IS_TRACK_ERRORS = TRACK_ERRORS === 'true';
export const IS_PROD = PROJECT_ENV === 'prod';
export const IS_DEV = PROJECT_ENV === 'dev';
export const IS_LOCAL = PROJECT_ENV === 'local';
export const IS_NEW_FEED = true;
//
