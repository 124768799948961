import { IS_LOCAL, IS_NEW_FEED, IS_PROD } from "./lib/consts/general";

const theme = 'bestreviews.zone';
const localRoot = `http://localhost:9000/api`;
const apiRoot = IS_LOCAL ? `${localRoot}/v1` : `https://${IS_PROD ? 'feed' : 'feed-stage'}.${theme}/api/v1`;
const apiRootV2 = IS_LOCAL ? `${localRoot}/v2` : `https://${IS_PROD ? 'feed' : 'feed-stage'}.${theme}/api/v2`;
const apiRootNewFeed = IS_LOCAL ? `${localRoot}/v2` : `https://${IS_PROD ? 'api' : 'api-stage'}.${theme}`;
const cheramiUrl = IS_PROD ? `//cherami.${theme}/report/fs` : `stage.cherami-api.com/report/fs`;
const pixelsUrl = IS_PROD ? `https://pixel-prod.${theme}/api/v1/pixels/add` : `https://pixel-stage.${theme}/api/v1/pixels/add`;
const autocompleteAPI = `https://sug.${theme}/v1/sug`;
const topcompleteAPI = `${apiRootV2}/ac/topten`;
const contactAPI = `${apiRoot}/contact/post`;
const searchAPI = IS_NEW_FEED ? `${apiRootNewFeed}/offers/query` : `${apiRoot}/products/list`;
const top10API = `${apiRoot}/top10`;
const ctoUrl = `https://track09.com/go/pixel`;
const captchaKey = '6LeIrdgZAAAAAKJdvaquKJoJ6_J_2xeXC0wzHg1C';
const allowYResults = true;
const feedProps = {
	mobile: {
		startWithTextAds: true,
		maxScramblesInPage: 1,
		textAdsToScrambleAmount: 2,
		productAdsToScrambleAmount: 0,
		pageSize: 16,
	},
	tablet: {
		startWithTextAds: true,
		maxScramblesInPage: 1,
		textAdsToScrambleAmount: 2,
		productAdsToScrambleAmount: 0,
		pageSize: 20,
	},
	computer: {
		startWithTextAds: true,
		maxScramblesInPage: 1,
		textAdsToScrambleAmount: 2,
		productAdsToScrambleAmount: 0,
		pageSize: 25,
	},
};

const head = {
	descriptions: {
		'/': () => 'Best Deals across the web. Daily deals and discounts. Save money on your shopping now.',
		'/404': () => 'Best Deals across the web. Daily deals and discounts. Save money on your shopping now.',
		'/_error': () => 'Best Deals across the web. Daily deals and discounts. Save money on your shopping now.',
		'/b/[...best]': () => 'Best Deals across the web. Daily deals and discounts. Save money on your shopping now.',
		'/best/[...best]': () => 'Best Deals across the web. Daily deals and discounts. Save money on your shopping now.',
		'/about': () => 'Best Deals across the web. Daily deals and discounts. Save money on your shopping now.',
		'/contact': () => 'Best Deals across the web. Daily deals and discounts. Save money on your shopping now.',
		'/privacy': () => 'Best Deals across the web. Daily deals and discounts. Save money on your shopping now.',
		'/terms': () => 'Best Deals across the web. Daily deals and discounts. Save money on your shopping now.',
		'/s/[q]': () => 'Best Deals across the web. Daily deals and discounts. Save money on your shopping now.',
		'/do-not-sell-my-personal-information': () => 'Best Deals across the web. Daily deals and discounts. Save money on your shopping now.',
		'/cookie-declaration': () => 'Best Deals across the web. Daily deals and discounts. Save money on your shopping now.',
	},
	titles: {
		'/': () => 'BestReviews.zone - Products Worth Buything',
		'/404': () => 'Page Not Found | BestReviews.zone',
		'/_error': () => 'Page Not Found | BestReviews.zone',
		'/b/[...best]': (cat, amount = '10') => `Shop ${amount} Best ${cat} | BestReviews.zone`,
		'/best/[...best]': (cat, amount = '10') => `Shop ${amount} Best ${cat} | BestReviews.zone`,
		'/about': () => 'About Us | BestReviews.zone',
		'/contact': () => 'Contact Us | BestReviews.zone',
		'/privacy': () => 'Privacy Policy | BestReviews.zone',
		'/terms': () => 'Terms Of Service | BestReviews.zone',
		'/s/[q]': (q = '') => `Shop Best ${q} | BestReviews.zone`,
		'/do-not-sell-my-personal-information': () => 'Do Not Sell My Personal Information | BestReviews.zone',
		'/cookie-declaration': () => 'Cookie Declaration | BestReviews.zone',
	},
};

const config = {
	head,
	theme,
	cheramiUrl,
	pixelsUrl,
	ctoUrl,
	searchAPI,
	contactAPI,
	autocompleteAPI,
	top10API,
	captchaKey,
	topcompleteAPI,
	allowYResults,
	feedProps
};
export default config;
