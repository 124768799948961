import { INITIAL_STATE } from './initial-state';
import { STORE_SESSION_KEY, IS_CLIENT, DEFAULT_CID, IS_PROD, IS_DEBUG } from '../../consts/general';
import { sessionGet, sessionSet } from '../../utils/session';
import { prompt } from '../prompt';
import { isFunction } from '../../utils/is-func';
import { categoryNameFromUrl, paramsFromUrl } from 'src/lib/utils/parse-from-url';
import { isTop10Page } from 'src/lib/utils/whoami';
import { updateDevices } from '../devices-ua';
import config from 'src/config';

/**
 * Main state object, will be replaced with each update
 * @type {{}}
 */
let store;
/**
 * maps all keys to a Set of handlers
 * @type {Object.<string,Set<function>>}
 */
const handlers = {};

/**
 * Write something
 * !TODO
 * @param {*} preloadedState
 * @returns
 */
const createStore = (preloadedState = {}) => {
	const _store = {
		state: { ...INITIAL_STATE, ...preloadedState },
		getState: () => {
			return _store.state;
		},
		setState: state => {
			_store.state = state; // TODO - check
		},
	};
	return _store;
};

/**
 * Will run only on client
 * @param {*} initialState
 * @returns
 */
export const resetClientState = (initialState = {}, asPath = '', q = '') => {
	const { pathname, href } = location;
	const urlParams = paramsFromUrl(href);
	urlParams.q = q;

	// Get store values from the session storage
	const FROM_SESSION = {
		...(v => {
			try {
				if (v) return JSON.parse(v);
				return {};
			} catch (e) {
				prompt.error('Failed to parse session data');
				return {};
			}
		})(sessionGet(STORE_SESSION_KEY)),
	};

	// parse filters from the url
	const usedFilters = {};
	if (urlParams.isFreeShipping) usedFilters.isFreeShipping = true;
	if (urlParams.brands) usedFilters.brands = urlParams.brands.split(',');
	if (urlParams.minDiscount) usedFilters.minDiscount = urlParams.minDiscount.split(',').map(a => +a);
	if (urlParams.color) usedFilters.color = urlParams.color;
	if (urlParams.size) usedFilters.size = urlParams.size;
	if (urlParams.condition) usedFilters.condition = urlParams.condition;
	if (urlParams.fcategoryId) usedFilters.fcategoryId = urlParams.fcategoryId;
	if (!isNaN(+urlParams.minPrice)) usedFilters.minPrice = +urlParams.minPrice;
	if (!isNaN(+urlParams.maxPrice)) usedFilters.maxPrice = +urlParams.maxPrice;

	updateM(
		'RESET STORE FROM CLIENT',
		{
			...initialState,
			...FROM_SESSION,
			urlParams,
			currentPage: asPath,
			// sessionId: sessionGet(FRM_SESSION_ID, uuid()),
			categoryName: isTop10Page() ? categoryNameFromUrl(pathname) : '',
			cid: +(urlParams.cid || DEFAULT_CID),
			isCompliant: +(urlParams.cid || DEFAULT_CID) === DEFAULT_CID,
			startTime: Date.now(),
			devices: updateDevices(navigator.userAgent, true),
			theme: config.theme,
			pageMode: false,
			infScroll: true,
			usedFilters,
			q,
		},
		false,
		true
	);
};

/**
 * In server side page add this function to the beginning of the function
 * @returns 
 */
export const resetStore = () => {
	store = createStore();
	return store;
};

// https://github.com/vercel/next.js/blob/canary/examples/with-redux/store.js - loosley based on
export const initializeStore = preloadedState => {
	store = createStore(preloadedState);
	return store;
};

/**
 * Subscribe to the specified keys on the STATE object
 * @param {string|Array.<string>} keys - this members changes will execute the handler
 * @param {function} handler
 */
export const select = (keys, handler) => {
	keys = Array.isArray(keys) ? keys : [keys];
	const stopper = handler(store.getState());
	if (isFunction(stopper)) {
		selectUntil(stopper, keys, handler, false);
	} else {
		keys.forEach(key => {
			(handlers[key] = handlers[key] || new Set()).add(handler);
		});
	}
};

/**
 * Subscribe to the specified keys on the STATE object once when condition returns true
 * @param {function} condition - a function returning a boolean for stopping the select subscription and running the handler
 * @param {string|Array.<string>} keys - this members changes will execute the handler
 * @param {function} handler
 * @param {boolean} [runHandlerOnRun] defaults to true
 */
export const selectUntil = (condition, keys, handler, runHandlerOnRun = true) => {
	const handlerWrapper = state => {
		if (condition(state)) {
			if (runHandlerOnRun) handler(state);
			unselect(handlerWrapper);
		}
	};
	select(keys, handlerWrapper);
};

/**
 * Get a current STATE value without subscribing
 * @param {string} key
 * @return {*}
 */
export const getEntry = key => {
	if (Array.isArray(store.getState()[key])) {
		return [...store.getState()[key]];
	}
	if (typeof store.getState()[key] === 'object') {
		return {
			...store.getState()[key],
		};
	}
	return store.getState()[key];
};

const updateSessionState = data => {
	// add only properties from store needed for page navigation
	const sessionState = {
		navEvents: data.navEvents,
		// test: data.test,
	};
	sessionSet(STORE_SESSION_KEY, JSON.stringify(sessionState));
};

/**
 * Merge the store.getState() with a new updated data and invoke all relevant handlers
 * @param {object} newData
 * @param {boolean} [silent] defaults to true, if trigger all handlers
 * @param {boolean} [replace] true means none defined members will be lost
 */
export const update = (newData = {}, silent = false, replace = false) => {
	const keys = Object.keys(newData);
	if (keys.length) {
		store.setState(replace ? { ...newData } : { ...store.getState(), ...newData });
		if (IS_CLIENT) {
			updateSessionState(store.getState());
			// Just for debugging
			if (IS_DEBUG) {
				window.ss = () => ({
					...store.getState(),
				});
			}
		}
		if (!silent) {
			const handlersToRun = new Set();
			keys.forEach(key => {
				if (handlers[key]) {
					handlers[key].forEach(handler => {
						handlersToRun.add(handler);
					});
				}
			});
			handlersToRun.forEach(handler => handler(store.getState()));
		}
	}
};

/**
 * Same as update but with a log message, better use this!
 * @param {string} updateName
 * @param {object} newData
 * @param {boolean} [silent] defaults to true, if trigger all handlers
 * @param {boolean} [replace] true means none defined members will be lost
 */
export const updateM = (updateName, newData, silent, replace = false) => {
	update(newData, silent, replace);
	prompt.debug(`UPDATE${silent ? ' (SILENT)' : ''} -> ${updateName}`, IS_PROD ? JSON.stringify(newData) : newData);
};

/**
 * Unsubscribe a handler from the STATE
 * @param {function} handler
 */
export const unselect = handler => {
	Object.keys(handlers).forEach(key => {
		handlers[key].delete(handler);
	});
};
