export const routes = {
	HOME: '/',
	PRIVACY: '/privacy',
	TERMS: '/terms',
	ABOUT: '/about',
	CONTACT: '/contact',
	TOP10_BEST: '/best',
	SEARCH: '/s',
	404: '/404',
	DNSMPI: '/do-not-sell-my-personal-information',
	COOKIE: '/cookie-declaration',
};
