/**
 * @param domString
 * @return {HTMLElement}
 */
/**
 * @param domString
 * @return {HTMLElement}
 */
export function str2dom(domString) {
	const div = document.createElement('div');
	div.innerHTML = domString.trim();
	return /** @type {HTMLElement} */ div.firstChild;
}

/**
 * Remove all child nodes of a given node
 * @param elm
 */
export function emptyElement(elm) {
	while (elm && elm.firstChild) {
		elm.removeChild(elm.firstChild);
	}
}

/**
 * @param {Node|string} container - an element or a selector
 * @param {string|Element} content
 * @param {boolean} empty the container element before appending
 * @return {HTMLElement}
 */
export function embed(container, content, empty = true) {
	const component = content instanceof HTMLElement ? content : str2dom(content);
	container = container instanceof Element ? container : dqs(container);
	if (empty) emptyElement(container);
	container.appendChild(component);
	return component;
}

/**
 * @param {Node|string} container - an element or a selector
 * @return {HTMLElement}
 */
export function remove(container) {
	container = container instanceof Element ? container : dqs(container);
	if (container) container.parentNode.removeChild(container);
	return /** @type HTMLElement */ container;
}

/**
 * alias for document.querySelector
 * @param {string} selector
 * @return {Element}
 */
export const dqs = selector => /** @type {Element} */ document.querySelector(selector);
/**
 * alias for document.querySelectorAll
 * @param {string} selector
 * @returns {NodeListOf<Element>}
 */
export const dqsa = selector => document.querySelectorAll(selector);

/**
 * @param {Node} container
 * @param {string} selector
 * @returns {Element}
 */
export const cqs = (container, selector) => container.querySelector(selector);

/**
 * alias for container.querySelectorAll
 * @param {Node} container
 * @param {string} selector
 * @returns {NodeListOf<Element>}
 */
export const cqsa = (container, selector) => container.querySelectorAll(selector);

/**
 * Add script url to container
 * @param {String} url
 * @param {Node} container
 */
export const addScript = (url, container = document.head, js = '') => {
	const script = document.createElement('script');
	url ? script.src = url : '';
	script.type = 'text/javascript';
	js ? script.innerHTML = js : '';
	container.appendChild(script);
	return script;
};